@import './variables.scss';

.body {
    padding: 15px;
    
}
.componentPdf {
    display: block;
}

.modal-top {
    svg {
        width: 40px;
    }
}

.logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 25px;
    margin-top: 25px;
    img {
        width: 20%;
        margin-top: -10px;
        margin-right: 5px;
    }
}
.headerTable {
    width: 100%;
}
.modalTable {
    margin-top: 10px;
    text-align: left;
    width: 100%;
    thead {
        padding: 0 10px;
        background-color: $background_white;
        font-size: 14px;
        th {
            padding: 7px 5px;
        }
    }
    td {
        padding: 15px 0;
    }
    .center {
        text-align: center !important;
    }
    .sale span {
        background-color: $blind_lightblue;
        color: white;
        padding: 5px;
        border-radius: 5px;
    }
    .originalPrice {
        font-size: 16px;
        text-decoration: line-through;
        color: grey;
        margin-left: 10px;
    }

}
.modal-footer {
    button {
        background-color: #47dfed;
        color: white;
        border-radius: 10px;
    }
}

.signSection {
    padding: 15px;
    display: flex;
    flex-direction: column;
    .signRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 450px;
        align-items: flex-end;
        .sign {
            color: black;
            border-top: 2px solid black;
        }
    }
    .adviceRow {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-top: 50px;
    }
}

@media (max-width: 640px) {
    .modal-footer {
        margin-bottom: 4rem;
    }
}

.body-content{
    width: 65rem;
}