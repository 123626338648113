@import './variables.scss';

.label-discount  {
    background-color: #00add8;
    color: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.2);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 125px;
    text-align: center;
}

.label-strong {
    font-weight: bold;
}

.label-discount:hover {
    white-space: normal;
}

.stateList {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .listFilter {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        button.nav-link {
            background-color: $blind_lightblue;
            color: white;
            border-radius: 23px;
            height: 30px;
            margin-right: 5px;
            font-size: 15px;
            width: 190px;
            img {
                margin-right: 5px;
                width: 25px;
            }
        }
        button.nav-link.selected {
            background-color: transparent;
            color : $font_base;
            border-radius: 23px;
            border: 1px solid $font_base;
            height: 30px;
            font-size: 15px;
            width: 100px !important;
            margin-right: 5px;

            .dismissFilter {
                font-size: 14px;
                color: $blind_lightblue;
                margin-left: 10px;
            }
        }

        hr {
            color: grey;
            border-top: 1px solid $line_grey;
            width: 75%;
        }

        .dropdown-item {
            padding: 15px;
        }

        .dropdown-menu {
            left: 7px;
            top: 110%;
        }
    }

    .listBody {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-bottom: 25px;
        .pedRow {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            margin-top: 20px;
        }
        .pedColumn {
            display: flex;
            flex-direction: column;
            width: 85%;
        }
        .detail {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: white;
            padding: 10px;
            border-radius: 10px;
            box-shadow: 0px 3px 6px #E4E6EF;
            margin-bottom: 1px;
            table {
                text-align: left;
                width: 97%;
                thead {
                    font-size: 14px;
                    border-bottom: 1px solid $line_grey2;
                    th {
                        max-width: 180px;
                    }
                }
                td {
                    padding: 10px 20px 10px 0;
                    max-width: 180px;
                }
            }
        }
        .collapse {
            border-radius: 10px;
            .card-body {
                padding: 0;
            }
            .p-left {
                padding-left: 10px;
            }
            .productImg {
                width: 50px;
                margin-right: 5px;
            }
            .center {
                text-align: center !important;
                .label {
                    width: 60px;
                }
            }
            table{
                text-align: left;
                width: 100%;
                border-bottom: 1px solid $line_grey2;

                thead {
                    padding: 5px  10px;
                    background-color: $background_white;
                    font-size: 13px;
                    text-align: center;
                    th {
                        padding: 7px;
                    }
                }
                .originalPrice {
                    font-size: 13px;
                    text-decoration: line-through;
                    color: grey;
                    margin-left: 10px;
                }
                td {
                    padding: 15px 0;

                }
                .sale span {
                    background-color: $blind_lightblue;
                    color: white;
                    padding: 5px;
                    border-radius: 5px;
                }
            }
            .totalRow {
                display: flex;
                flex-direction: row;
                font-size: 20px;
                justify-content: flex-end;
                padding: 5px 10px;
                .total {
                    color: $font_base;
                    margin: 0 15px 15px 0;
                }
                .amount {
                    color: $blind_lightblue;
                }
            }
            .card {
                border: none;
            }
            .card::before{
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                background-repeat: repeat;
                height: 10px;
                background-size: 20px 20px;
                background-image:
                  radial-gradient(circle at 10px -5px, transparent 12px, $background_white 13px);
              }
              .card::after{
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                background-repeat: repeat;
                height: 15px;
                background-size: 40px 20px;
                background-image:
                  radial-gradient(circle at 10px 15px, $background_white 12px, transparent 13px);
              }
        }
        .collapsing {
            .card.card-body {
                display: none;
            }
        }
        .actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: white;
            border-radius: 10px;
            padding: 10px 10px;
            max-width: 345px;
            box-shadow: 0px 3px 6px #E4E6EF;
            min-height: 100px;
            button {
                display: flex;
                flex-direction: row;
                color: #272D4B;
                padding: 0;
                text-align: left;
                align-items: center;
                font-size: 14px;
                margin-right: 5px;
                padding: 5px 10px;
                img {
                    width: 45px;
                    margin-right: 8px
                }
            }
        }
        .noResult {
            height: 200px;
            font-weight: 600;
        }
    }
}

.ReactModalPortal {
    .modal-top {
        height: 50px;
        svg {
            width: 40px;
        }

    }

    .logo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        margin-bottom: 25px;
        img {
            width: 20%;
            margin-top: -7px;
            margin-right: 5px;
        }
    }

    .headerTable {
        width: 100%;
    }

    .desktopTable {
        text-align: left;
        border-bottom: none;
        thead {
            padding: 0 10px;
            background-color: $background_white;
            font-size: 14px;
            th {
                padding: 7px 5px;

            }
        }
        td {
            padding: 15px 0;
            text-align: center;

        }
        .left {
            text-align: left !important;
        }
        .sale span {
            background-color: $blind_lightblue;
            color: white;
            padding: 5px;
            border-radius: 5px;
        }
    }

    .modal-footer {
        button {
            background-color: #47dfed;
            color: white;
            border-radius: 10px;
        }
    }
}

.mobileActions {
    display: none;
}

.mobileDeatilHolder {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
}

.modileTableAltHeader {
    display: none;
}

.mobileTable {
    display: none;
}

.mobileDesc {
    display: none;
}

@media (max-width: 640px) {
    .actions {
        display: none !important;
    }

    .ReactModalPortal {
        width: 60rem;
        .top {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            .close {
                width: 50px;
                svg {
                    width: 45px;
                    path {
                        fill: $font_base;
                    }
                }
            }
            .back {
                width: 50px;
                display: none;
                path {
                    fill: $blind_lightblue;
                }
            }
        }
        .modal-top {
            margin: 0.8rem;
        }
        .header{
            h3 {
                font-size: 20px;
              }
            .order {
                height: 50px;
                padding: 10px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background-color: $background_white;
                border-radius: 6px;
                margin: 25px 0;
                span {
                    width: 90px;
                    margin: 0 10px 0 5px;
                }
                span.line {
                    border-right: 1px solid $font_base;
                    padding-right: 10px;
                }
                .state {
                    width: 105px;
                }
            }
        }
        form {
            width: 100%;
            label {
                width: 175px;

                .regular {
                    font-weight: 400;
                }
            }
            textarea {
                border-radius: 5px;
                height: 150px;
                width: 100%;
                padding: 10px;
            }
            .btn {
                width: 100%;
                background-color: #47dfed;
                color: white;
                border-radius: 5px;
                align-items: center;
                height: 55px;
                margin-bottom: 15px;
            }
            .btn.cancel{
                background-color: transparent;
                color: $font_base;
                border: 1px solid $font_base;
            }
            .btn.confirmCancel{
                background-color: $red_error;
                color: white;
            }
        }

    }

    .label-discount  {
        max-width: 200px;
    }

    .label-discount:hover {
        white-space: nowrap;
    }

    .desktopTable {
        display: none;
    }

    .modileTableAltHeader {
        display: flex;
        flex-direction: column;

        .label {
            text-align: left;
            font-size: 14px;
        }
        .productsNameList {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            font-size: 14px;
            padding: 0 0 5px 15px;

            .productName {
                padding: 5px;
            }
        }
    }

    .mobileTable {
        display: block;

        .productIt {
            display: flex;
            flex-direction: row;
            padding-top: 5px;
            border-bottom: 1px solid $line_grey2;
            border-top: 1px solid $line_grey2;
            .thead {
                padding: 0 !important;
                background-color: #fff !important;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .tr {
                    display: flex;
                    padding-left: 5px;
                    flex-direction: column;
                    border: none;
                    text-align: left;


                    .th {
                        font-size: 13px;
                        height: 35px;
                    }
                }
            }
            .tbody {
                .tr {
                    display: flex;
                    flex-direction: column;

                    .td {
                        padding: 0 0  7px 5px !important;
                        font-size: 13px;
                        height: 35px;
                        text-align: left;

                        .label {
                            width: 60px;
                        }
                    }
                }
            }
        }
    }

    .totalRow {
        justify-content: center !important;
        .total {
            color: $font_base !important;
        }
        .amount {
            color: $font_base !important;
        }
    }

    .stateList {
        .listBody {
            .pedColumn {
                width: 100%;
                .card {
                    margin: 0 5px;
                }
                .mobileActions {
                    background: white;
                    border-radius: 10px;
                    display: flex;
                    justify-content: space-around;
                    height: 140px;
                    margin-right: 5px;
                    box-shadow: 0px 3px 6px #E4E6EF;

                    .border-bottom {
                        border-bottom: 1px solid $line_grey2;
                    }
                    button {
                        padding: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img {
                            width: 45px;
                            margin: 0;
                        }
                    }
                }
            }
            .detail {
                align-items: flex-start;
                background: transparent;
                padding: 0;
                box-shadow: none;

                .mobileDeatilHolder {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    margin-right: 5px;
                    background: #fff;
                    box-shadow: 0px 3px 6px #E4E6EF;
                    border-radius: 10px;
                    margin-left: 5px;
                    padding-left: 5px;
                    width: 100%;

                }
                table {
                    margin-right: none !important;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    thead {
                        border-bottom: none;
                        tr {
                            display: flex;
                            flex-direction: column;
                            position: relative;

                            th {
                                padding: 5px 0;
                                font-size: 13px;
                                width: 100%;
                                height: 70px;
                            }
                        }
                    }
                    tbody {
                        tr {
                            display: flex;
                            flex-direction: column;
                            td {
                                padding: 8px 0 5px 5px !important;
                                font-size: 13px;
                                height: 70px;
                            }

                            time {
                                font-size: 12px;
                            }
                        }
                    }
                }
                img {
                    width: 25px;
                    margin: 8px;
                }
            }
        }
        .listFilter {
            display: none;
        }
    }

    .comments-btn {
        display: none !important;
    }
}

@media (max-width: 700px) {
    .mobileDeatilHolder {
        width: 100%;
    }
}

.telephone, .email {
    position: relative;
    top: -2px;
    font-size: 13px;
}

.origin, .name {
    position: relative;
    top: -5px;
    font-size: 13px;
}

.date, .total, .order {
    font-size: 13px;
}

.totalModal {
  position: relative;
  top: -3px;
  font-size: 17px;
}

.unitPrice {
  position: relative;
  left: 2rem;
}

@media (min-width: 1600px) {
  .telephone, .email, .origin, .name, .date, .total, .order  {
      font-size: 18px;
  }

  .unitPrice {
    left: 6rem;
  }

  .barcode {
    position: relative;
    left: 5.5rem;
  }
}

.originalPrice {
  font-size: 13px;
  text-decoration: line-through;
  color: grey;
  margin-left: 10px;
}

.ofertSticker {
  position: relative;
  top: -8px;
}
