@import './variables.scss';

.loginComponent {
    .has-error {
        border: 1px solid $blind_lightblue;
    }

    form {
        width: 35%;
        // margin-top: 50px;
        background-color: white;
        padding: 25px 40px 18px 40px;
        border-radius: 10px;
        border-color: 1px solid #E7E9F2;
    }

    .forgot {
        cursor: pointer;
    }

    .hide {
        display: none;
    }

    .forgotForm {
        width: 100%;
        background-color: white;
        padding: 25px 40px 18px 40px;
        border-radius: 10px;

        .formErrors {
            min-height: 34px;
        }

        .submit-success-message {
            padding: 20px 0;
            font-weight: bold;
        }
    }

    .blackForgot {
        font-weight: 500;
        cursor: pointer;
    }
    .form-control {
        margin-bottom: 40px;
    }

    .logo {
        width: 250px;
        height: 100px;
        margin: auto;
    }

    .cont-logo {
        justify-content: center !important;
        margin-bottom: 25px;
    }

    .name {
        background-image: url('./../assets/Icon\ metro-question.svg');
        background-size: 20px;
        background-position: 10px;
        background-repeat: no-repeat;
        padding-left: 40px;
    }

    .user {
        background-image: url('./../assets/user-circle.svg');
        background-size: 20px;
        background-position: 10px;
        background-repeat: no-repeat;
        padding-left: 40px;
    }

    .password {
        background-image: url('./../assets/Icon\ ionic-md-lock.svg');
        background-size: 20px;
        background-position: 10px;
        background-repeat: no-repeat;
        padding-left: 40px;
    }

    .forgot {
        margin-top: 15px;
    }

    .register {
        margin-top: 75px;
    }

    .news {
        margin-top: 25px;
    }

    .formErrors {
        margin-top: 15px;
    }

}
.loginComponent.holder {
    min-height: 100vh;
    justify-content: center;
}

@media (max-width: 640px) {
    .loginComponent {
        form {
            width: 100%;
            height: 100vh;
            border-radius: 0;
            padding: 25px 20px 18px 20px;
        }
    }
}
