// html, body, #root, #root>div {
//     height: 100%
//   }

#root {
    display: flex;
    flex-direction: column;
}
  label {
    font-family: 'Gilroy-Bold';
}

a {
    text-decoration: none;
    color: $font_base;
}

a:hover {
    text-decoration: none;
    color: $font_base;
}

input[type="checkbox"] {
    width: 16px;
}

input {
    color: $dark_gray;
    font-size: 16px;
    letter-spacing: 0.5px;

}

button {
    border: none;
    background-color: transparent;
}

button:focus {
    outline: none;
}

.custom-control-input:checked~.custom-control-label::before {
    background-color: white;
    border-color: $light_grey;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url(./../assets/check.png);
    background-size: 10px;
}

.btn-primary {
    background-color: $light_blue;
    color: white;
    border: 0;
    height: 60px;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.5px;
}

.btn-primary:hover {
    background-color: $light_blue;
}

.form-control {
    height: 50px;
}

.center {
    justify-content: center;
}

.regular {
    font-family: 'Gilroy'
}

.black {
    color: $black !important;
}

.bold {
    font-family: 'Gilroy-Bold';
}

.holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    min-height: 85vh;
}

.dropdown-item {
    font-size: 14px;
}

@media (max-width: 640px) {
    .holder {
        padding: 0;
    }
  }