@import './variables.scss';

.nav {
    width: 100%;
    margin: 25px 0;
}

.nav-pills {
    .nav-link {
        width: 200px;
        background-color: $light_blue;
        color: white;
        border-radius: 10px;
        height: 70px;

        svg {
            width: 45px;
            margin-right: 16px;
            path {
                fill: white;
            }
        }
        .chevron {
            display: none;
        }

    }
    .nav-link:hover {
        background-color: $font_base;
        svg path {
            fill: $light_blue;
        }
    }
    .nav-link.active {
        background-color: $font_base;
    }
}

.nav-fill {
    .nav-item {
        flex: unset;
        position: relative;
    }
    strong {
      background-color: #272D4B;
      color: white;
      padding: 3px 8px;
      float: right;
      position: absolute;
      right: -5px;
      border-radius: 50%;
      top: -10px;
      font-size: 12px;
    }

    .nav-item:last-child {
        flex: unset;
        margin-top: 0rem;
    }
}

.tab-content {
    width: 100%;
}

@media (max-width: 640px) {
        .nav-pills {
            flex-direction: row;
        .nav-link {
            width: unset !important;
            svg {
                width: 25px;
                margin-right: 0;
            }
            span {
                display: none;
            }
        }
        .nav-link.active {
            width: 150px;
            svg {
                margin-right: 5px;
            }
            span {
                display: block;
                font-size: 15px;
            }
            .chevron {
                display: flex;
                margin-left: 5px;
                fill: white;
            }
        }
        .nav-item:last-child {
            flex: unset;
            margin-top: 1rem;
        }
    }
}
