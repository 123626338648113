@import './styles/variables.scss';
@import './styles/common.scss';

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Gilroy-Regular.otf'); /* IE9 Compat Modes */
  src: local('Open Sans'), local('OpenSans'),
       url('./fonts/Gilroy-Regular.otf') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/Gilroy-Regular.otf') format('otf'), /* Super Modern Browsers */
       url('./fonts/Gilroy-Regular.otf') format('otf'), /* Modern Browsers */
       url('./fonts/Gilroy-Regular.otf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/Gilroy-Regular.otf#Gilroy') format('otf'); /* Legacy iOS */
}

@font-face {
  font-family: 'Gilroy-light';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/Gilroy-Light.otf'); /* IE9 Compat Modes */
  src: local('Open Sans'), local('OpenSans'),
       url('./fonts/Gilroy-Light.otf') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/Gilroy-Light.otf') format('otf'), /* Super Modern Browsers */
       url('./fonts/Gilroy-Light.otf') format('otf'), /* Modern Browsers */
       url('./fonts/Gilroy-Light.otf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/Gilroy-Light.otf#Gilroy-light') format('otf'); /* Legacy iOS */
}

@font-face {
  font-family: 'Gilroy-bold';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/Gilroy-Bold.otf'); /* IE9 Compat Modes */
  src: local('Open Sans'), local('OpenSans'),
       url('./fonts/Gilroy-Bold.otf') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/Gilroy-Bold.otf') format('otf'), /* Super Modern Browsers */
       url('./fonts/Gilroy-Bold.otf') format('otf'), /* Modern Browsers */
       url('./fonts/Gilroy-Bold.otf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/Gilroy-Bold.otf#Gilroy-bold') format('otf'); /* Legacy iOS */
}

@font-face {
  font-family: 'Gilroy-extrabold';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/Gilroy-ExtraBold.otf'); /* IE9 Compat Modes */
  src: local('Open Sans'), local('OpenSans'),
       url('./fonts/Gilroy-ExtraBold.otf') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/Gilroy-ExtraBold.otf') format('otf'), /* Super Modern Browsers */
       url('./fonts/Gilroy-ExtraBold.otf') format('otf'), /* Modern Browsers */
       url('./fonts/Gilroy-ExtraBold.otf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/Gilroy-ExtraBold.otf#Gilroy-extrabold') format('otf'); /* Legacy iOS */
}

.App {
  text-align: center;
  font-family: 'Gilroy';
  background-color: $background_white;
  // background-color: violet;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


