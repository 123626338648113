/* VARIABLES */

$light_blue: #47dfed;
$background_white: #F8F9FD;
$black: #222221;
$dark_gray: #434343;
$light_grey: #DCDCDC;
$input_grey: #3F3F3F;
$font_base: #272D4B;
$blind_lightblue: #23D6BE;
$advice: #EDF0F8;
$line_grey: #D0D3E0;
$line_grey2: #DEE0E8;
$red_error: #D62323;

