@import './variables.scss';

.mobileNav {
    display: none;
}

.desktopNav {
    display: flex;
}

.navbar .logo {
    width: 250px;
    height: 85px;
}

.cont-logo {
    justify-content: center !important;
}

.navbar {
    background-color: white;
    padding: 15px 3%;
    font-size: 14px;
    .collapse {
        justify-content: space-between;
    }
}

button.nav-link {
    line-height: inherit;
}

.navbar-brand {
    margin-right: 3.5rem;
    padding: 0;
}

.nav-link {
    color: $font_base;
}

.navbar-nav span {
    display: flex;
    align-items: center;
}

.nav-item {
    padding-left: 4px;
}

.formNuser {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.formNuser form {
    width: inherit;
}

.formNuser input {
    width: 300px !important;
    height: 30px;
}

.search {
    font-size: 14px;
    border-color: 1px solid $background_white;
    border-radius: 10px 35px 35px 10px;
}
.search-button {
  background-image: url('./../assets/lupa.svg');
  background-size: 20px;
  background-position: 0;
  background-repeat: no-repeat;
  position: relative;
  width: 23px;
  height: 24px;
  left: -35px;
}
.search-button SPAN { display: none; }

.nav-item.userbutton {
    width: 150px;
    flex-direction: column;
}
.userCircle {
    width: 35px;
    margin-right: 10px;
}

.branchName {
    width: 35px;
    color: $blind_lightblue;
    text-align: left;
}

#navbarDropdown {
    display: flex;
    align-items: center;
}

.dropdown-toggle::after {
    display: none;
}

.block {
    display: block;
}

.nav-item {
    display: flex;
    flex-direction: row;
}

.nav-link {
    display: flex;
    align-items: center;
    width: 120px;
}

.w-160 {
    width: 160px;
}

.w-100 {
    width: 95px;
}

.end {
    justify-content: flex-end;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 8px;
    padding-right: 8px;
}

.links {
    display: none;
}

.link-history {
    display: flex;
}

.headerLink{
    font-weight: bold;
    font-size: 16px;
    color: $font_base;
    margin-bottom: 0;
    color: #fff;
    width: 9rem;
}

// MOBILE EXCEPTIONS
.navbar-brand.mobile {
    margin-right: 0;
}

.glass {
    padding: .25rem .75rem;
 }

.search-mobile {
  display: none;
}
.search-mobile .search-button {
  background-color: $light_blue;
  color: white;
  border-radius: 10px;
}

.search-mobile.open {
  display: block;
}

 #sidebar {
    display: flex;
    min-width: 300px;
    position: absolute;
    top: 0;
    flex-direction: column;
    .config {
        width: 100%;
        background-color: $advice;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        button {
            display: flex;
            justify-content: center;
        }
        img {
            width: 15px;
            margin-right: 5px;
        }
    }
}
.navbar-collapse {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
}

a[data-toggle="collapse"] {
    position: relative;
}

#sidebar .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}
.sidebarBack {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #272D4B80;
}
.sidebarBack.open {
    display: flex;
}

.userData {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 25px;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fd;
    position: relative;

    img {
        width: 80px;
        margin-right: 0;
    }

    .branchName {
        color: $font_base;
        width: 100%;
        text-align: center;
    }

    .close {
        position: absolute;
        top: 15px;
        left: 15px;
    }
}

.sidebarBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fd;
    padding-bottom: 50px;
    ul {
        width: 100%;
        margin-bottom: 185px;
        .nav-item {
            border-bottom: 1px solid #DCE0F0;
            display: flex;
            padding: 5px;
            width: 100%;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            .nav-link {
                width: 65%;
            }
            .icon-list {
                margin-right: 10px;
                width: 20px;
            }
            .chevron {
                margin-left: 10px;
            }
            .nav-link-bold{
                width: 65%;
                font-weight: bold;
                display: flex;
            }
        }

    }
    .buttonBlue {
        width: 200px;
        background-color: $light_blue;
        color: white;
        border-radius: 10px;
        height: 50px;
        svg {
            width: 45px;
            margin-right: 16px;
            path {
                fill: white;
            }
        }

    }
    .buttonBlue:hover {
        background-color: $font_base;
        svg path {
            fill: $light_blue;
        }
    }
    .buttonBlue.active {
        background-color: $font_base;
    }

}

@media (max-width: 640px) {

    .mobileNav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .desktopNav {
        display: none;
    }

    .formNuser.desktop {
        display: none;
    }

    #sidebar {
        .navbar-toggler {
            display: flex;
        }
        .navbar-brand.mobile {
            display: flex;
        }
        .glass {
            display: flex;
        }
    }

    #sidebar.show {
        margin-left: 0;
    }
}
/* Smartphones (landscape) ----------- */
@media only screen  and (min-width : 321px) {

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .navbar-nav {
        form {
            padding: 5px 10px;
            input {
                margin-right: 0 !important;
                width: 250px !important;
            }
        }
    }
}

.userNameContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    position: relative;
    top: 10px;
}

.userText, .pharmacyText, .operatorText {
    font-weight: bold;
    line-height: 2.5px;
}

.desktop {
    position: relative;
    left: -5rem;
}

.userMobile {
    margin-top: 15px;
}
