@import './variables.scss';

.collapse.comments {
    margin-top: 25px;
    padding: 35px;
    background-color: white;
    .title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        color: $blind_lightblue;
        align-items: center;
        font-size: 20px;
        letter-spacing: 3px;
        svg {
            margin-right: 10px;
            width: 30px;
            path {
                fill: $blind_lightblue;
            }
        }
    }
    .stateStep {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: $background_white;
        align-items: center;
        padding: 15px;
        border-radius: 10px;
        margin-bottom: 2px;
        svg {
            margin-right: 10px;
            width: 30px;
            path {
                fill: $font_base;
            }
        }
        .left {
            display: flex;
            p {
                margin-bottom: 0;
            }
        }
        .desc {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
        button {
            width: 200px;
            background-color: #47dfed;
            color: white;
            border-radius: 10px;
            height: 55px;
        }
        .mercadopago {
            text-align: left;
        }
        
    }
    .stateStep.button {
        justify-content: flex-end;
    }
    .changeState {
        padding: 15px 0;
        .btn {
            width: 310px;
            background-color: #47dfed;
            color: white;
            border-radius: 10px;
            align-items: center;
            height: 55px;
            svg {
                width: 25px;
                margin-left: 5px;
                path {
                    fill: white;
                }
            }
        }
        .cancel {
            background-color: $red_error;
            color: white;
        }
    }
    .none {
        display: none;
    }
}

.ReactModalPortal {
    .top {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .close {
            width: 50px;
            svg {
                width: 45px;
                path {
                    fill: $font_base;
                }

            }
        }
        .back {
            width: 50px;
            display: none;
            path {
                fill: $blind_lightblue;
            }
        }
    }
    .header{
        h3 {
            font-size: 20px;
        }
        .order {
            height: 50px;
            padding: 10px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background-color: $background_white;
            border-radius: 6px;
            margin: 25px 0;
            span {
                width: 90px;
                margin: 0 10px 0 5px;
            }
            span.line {
                border-right: 1px solid $font_base;
                padding-right: 10px;
            }
            .state {
                width: 105px;
            }
        }
        
    }
    form {  
        width: 100%;
        label {
            width: 175px;
            .regular {
                font-weight: 400;
            }
        }
        textarea {
            border-radius: 5px;
            height: 150px;
            width: 100%;
            padding: 10px;
        }
        .btn {
            width: 100%;
            background-color: #47dfed;
            color: white;
            border-radius: 5px;
            align-items: center;
            height: 55px;
            margin-bottom: 15px;
        }
        .btn.cancel{
            background-color: transparent;
            color: $font_base;
            border: 1px solid $font_base;

        }
        .btn.confirmCancel{
            background-color: $red_error;
            color: white;
        }
        .btn.confirmSend{
            background-color: #47dfed;
            color: white;
        }
    }
}

@media (max-width: 640px) {
    .collapse.comments {
        padding: 15px;
        .title {
            margin-bottom: 15px;
        }
        .stateStep {
            flex-direction: column-reverse !important;
            .time {
                width: 100%;
                padding-left: 40px;
                p {
                    color: #A2A6BE;
                    text-align: start;
                    font-size: 12px;
                    margin-bottom: 5px;
                }
            }
            .desc {
                text-align: left;
            }
            .left {
                align-items: center;
                width: 100%;
                svg {
                    min-width: 30px;
                }
            }
            button {
                border: 2px solid $blind_lightblue;
                background-color: transparent;
                color: $blind_lightblue;
                width: 90%;
            }
        }
    }
    
    .ReactModal__Content {
        width: 100%;
        align-items: center;
        .header {
            align-items: center;
            display: flex;
            flex-direction: column;
        }
        .top {
            justify-content: flex-start;
            .close {
                display: none;
            }
            .back {
                display: flex;
                svg {
                    width: 25px;
                }
            }
        }
        .header {
            h3 {
                text-align: center;
                width: 75%;
            }
            .order {
                flex-direction: column;
                height: 120px;
                span.line {
                    border: none;
                }
                .orderNum, .orderState {
                    display: flex;
                    flex-direction: row;
                    padding: 10px;
                }
            }
        }
    }
}
