@import './variables.scss';


/* Sticky footer styles
-------------------------------------------------- */
  .footer {
    background-color: $font_base;

    width: 100%;
    height: 45px;
    line-height: 60px; /* Vertically center the text there */
  }
  
  
  /* Custom page CSS
  -------------------------------------------------- */
  /* Not required for template or sticky footer method. */
  
  .container {
    width: auto;
    max-width: 680px;
    padding: 0 15px;
  }
  